<nav class="dkt nav nav-secondary mixed-loyalty-nav my-0">
  <a
    class="nav-item"
    [routerLink]="['/']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.home' | translate }}"
  >
    <span class="icon material-icons" aria-hidden="true">home</span>
    <label>{{ 'rewards.nav.home' | translate: { fgbdefault: 'Home' } }}</label>
  </a>

  <a
    class="nav-item"
    [routerLink]="['/rewards']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.rewards.screenreader' | translate: { fgbdefault: 'Move to Rewards page' } }}"
  >
    <span class="icon material-icons" aria-hidden="true">star</span>
    <label>{{ 'rewards.nav.rewards' | translate }}</label>
  </a>

  <a
    class="nav-item"
    [routerLink]="['/challenges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'rewards.nav.challenges.screenreader' | translate: { fgbdefault: 'Move to Challenges page' } }}"
  >
    <span class="icon material-icons" aria-hidden="true">flag</span>
    <label>{{ 'rewards.nav.challenges' | translate }}</label>
  </a>
</nav>
