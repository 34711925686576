<div class="barcode-component w-100 d-block d-lg-none border rounded" *ngIf="memberCard$ | async as memberCard">
  <div class="border-0 text-center" *ngIf="barcode$ | async as barcode">
    <div class="tier-logo" *ngIf="tierName">
      <img
        *ngIf="tierName == tierLevels.Mvp"
        alt=""
        src="assets/images/tier-logo/tier_logo_mvp.svg"
        draggable="false"
        height="18"
      />
      <img
        *ngIf="tierName == tierLevels.AllStar"
        alt=""
        src="assets/images/tier-logo/tier_logo_all_star.svg"
        draggable="false"
        height="18"
      />
      <img
        *ngIf="tierName == tierLevels.Legend"
        alt=""
        src="assets/images/tier-logo/tier_logo_legends.svg"
        draggable="false"
        height="18"
      />
      <img
        *ngIf="tierName == tierLevels.Pro"
        alt=""
        src="assets/images/tier-logo/tier_logo_pro.svg"
        draggable="false"
        height="18"
      />
    </div>
    <div class="rounded modal-body p-0 flex-column d-flex flex-wrap justify-content-around">
      <!--Barcode-->
      <div class="qr bg-white text-center rounded">
        <qrcode [qrdata]="barcode.Barcode" [width]="120" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
      </div>

      <div class="w-100 rounded-0 text-center mb-2">
        <div class="text-uppercase card-name font-heading font-size-20" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </div>
        <ng-container *notInPopulation="populations.Free">
          <div class="font-size-10">{{ memberCard.ProductCode }} - {{ memberCard.ExternalRefNumber }}</div>
        </ng-container>
        <ng-container *inPopulation="populations.Free">
          <div class="font-size-10">{{ 'product.code.free.name' | translate }} - {{ memberCard.ExternalRefNumber }}</div>
        </ng-container>
      </div>

      <!--Member Details-->

      <ng-container *ngIf="memberCard.RetailDiscountLevel != null || memberCard.RetailDiscountLevel != undefined" ;else default>
        <div
          class="text-black py-2 px-3 w-100 border-bottom-radius"
          [ngClass]="{
            'bg-primary': !tierName,
            'bg-tertiary': tierName == tierLevels.Pro,
            'bg-allstar': tierName == tierLevels.AllStar,
            'bg-legend': tierName == tierLevels.Legend,
            'bg-mvp': tierName == tierLevels.Mvp,
          }"
        >
          <div class="member-details row m-0 text-white justify-content-center">
            <div class="card-id col-2" *notInPopulation="[populations.Suite, populations.Pts]">
              <label class="font-size-20 font-heading mb-2">{{ pointsBalance$ | async }}</label>
              <div class="font-size-12 mb-0">{{ 'member.card.points.balance' | translate }}</div>
            </div>
            <div class="card-id col-6 text-center" *notInPopulation="[populations.Suite, populations.Pts]">
              <label class="font-size-20 font-heading mb-2">{{ combinedCard$ | async | FortressCurrencyPipe }}</label>
              <div class="font-size-12 mb-0">{{ 'member.card.ecash.balance' | translate }}</div>
            </div>
            <div class="card-id col-2 text-center" *notInPopulation="populations.Free">
              <label class="font-size-20 font-heading mb-2">{{ memberCard.DiscountLevel | getNumber }}%</label>
              <div class="font-size-12 mb-0">{{ 'member.card.discountlevel' | translate }}</div>
            </div>
            <div class="card-account-no pe-3 col-2 text-center" *notInPopulation="populations.Free">
              <label class="font-size-20 font-heading mb-2">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
              <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #default>
  <div class="member-details text-black pe-2 w-100 border-style-dkb bg-primary">
    <div class="row mb-2 justify-content-center">
      <ng-container>
        <div class="col-6 border-primary text-white border-end">
          <div class="card-id text-center">
            <label class="mb-0 font-size-14">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
            <div class="font-size-12">{{ 'member.card.retaildiscountlevel' | translate }}</div>
          </div>
        </div>
      </ng-container>

      <ng-container>
        <div class="col-6 card-id text-center mt-1 px-4">
          <label class="mb-0 font-size-14">{{ memberCard.DiscountLevel | getNumber }}%</label>
          <div class="font-size-12">{{ 'member.card.discountlevel' | translate }}</div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
