<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div
    *ngIf="memberCard.RetailDiscountLevel != null || memberCard.RetailDiscountLevel != undefined; else default"
    class="member-card text-center"
    [ngClass]="{
      'bg-tertiary': tierName == tierLevels.Pro || !tierName,
      'bg-allstar': tierName == tierLevels.AllStar,
      'bg-legend': tierName == tierLevels.Legend,
      'bg-mvp': tierName == tierLevels.Mvp,
    }"
  >
    <div class="tier-logo" *ngIf="tierName">
      <img
        *ngIf="tierName == tierLevels.Mvp"
        alt=""
        src="assets/images/tier-logo/tier_logo_mvp.svg"
        draggable="false"
        height="18"
      />
      <img
        *ngIf="tierName == tierLevels.AllStar"
        alt=""
        src="assets/images/tier-logo/tier_logo_all_star.svg"
        draggable="false"
        height="18"
      />
      <img
        *ngIf="tierName == tierLevels.Legend"
        alt=""
        src="assets/images/tier-logo/tier_logo_legends.svg"
        draggable="false"
        height="18"
      />
      <img
        *ngIf="tierName == tierLevels.Pro"
        alt=""
        src="assets/images/tier-logo/tier_logo_pro.svg"
        draggable="false"
        height="18"
      />
    </div>
    <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-flex justify-content-between align-items-center">
        <!--Name and ID-->
        <div
          class="card-id text-start mt-4"
          [ngClass]="{
            'text-white': tierName == tierLevels.Mvp || tierName == tierLevels.AllStar,
            'text-primary': tierName == tierLevels.Legend,
          }"
        >
          <p class="mb-0 card-name text-uppercase font-size-20" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-10">
            <div *notInPopulation="populations.Free">{{ memberCard.ProductCode }}</div>
            <div *inPopulation="populations.Free">{{ 'product.code.free.name' | translate }}</div>

            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <!--Logo-->
        <img
          alt=""
          *ngIf="tierName != tierLevels.Pro && tierName"
          src="assets/images/MyTwins_PrimaryLogo_full_white.png"
          draggable="false"
          class="card-logo"
        />
        <img
          alt=""
          *ngIf="tierName == tierLevels.Pro || !tierName"
          src="assets/images/MyTwins_PrimaryLogo_Dark.png"
          draggable="false"
          class="card-logo"
        />
      </div>

      <!--Balances-->
      <div class="mt-4 pt-2">
        <div
          class="d-flex align-items-end mb-3 row mx-0"
          [ngClass]="{
            'text-white': tierName == tierLevels.Mvp || tierName == tierLevels.AllStar,
            'text-primary': tierName == tierLevels.Legend || tierName == tierLevels.Pro,
          }"
        >
          <div class="card-id col-1 ps-0" *notInPopulation="[populations.Suite, populations.Pts]">
            <label class="font-size-20 font-heading mb-2">{{ pointsBalance$ | async }}</label>
            <div class="font-size-12 mb-0">{{ 'member.card.points.balance' | translate }}</div>
          </div>
          <div class="card-id col-5 text-center" *notInPopulation="[populations.Suite, populations.Pts]">
            <label class="font-size-20 font-heading mb-2">{{ combinedCard$ | async | FortressCurrencyPipe }}</label>
            <div class="font-size-12 mb-0">{{ 'member.card.ecash.balance' | translate }}</div>
          </div>
          <div class="card-id col-2 text-start ps-0" *notInPopulation="populations.Free">
            <label class="font-size-20 font-heading mb-2">{{ memberCard.DiscountLevel | getNumber }}%</label>
            <div class="font-size-12 mb-0">{{ 'member.card.discountlevel' | translate }}</div>
          </div>
          <div class="card-account-no pe-3 col-2 text-start" *notInPopulation="populations.Free">
            <label class="font-size-20 font-heading mb-2">{{ memberCard.RetailDiscountLevel | getNumber }}%</label>
            <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #default>
  <div class="member-card text-white bg-primary">
    <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-flex justify-content-between mt-3">
        <!--Name and ID-->
        <div class="card-id text-start">
          <p class="mb-0 card-name text-uppercase font-size-20" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </p>
          <div class="d-flex font-size-12">
            <div>{{ memberCard.ProductCode }}</div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
        <!--Logo-->
        <img alt="" class="card-logo" src="assets/images/Twins_logo.svg" draggable="false" />
      </div>
    </div>
  </div>
</ng-template>
<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
