<div class="full-page">
  <div class="container">
    <div class="d-flex align-items-center justify-content-center pt-lg-5 pt-4 pb-3">
      <img alt="" class="card-logo" src="assets/images/login-registration/login-logo.svg" draggable="false" />
    </div>

    <div class="panel rounded">
      <ng-container *ngIf="this.availableAccounts.length > 0; else noAccounts">
        <h2 class="mt-0 mb-4 text-start">{{ 'login.linked.account.title' | translate }}</h2>

        <div class="card mb-4" *ngFor="let account of availableAccounts">
          <div class="d-flex linked-container mb-2">
            <div class="section-info text-start">
              <div class="fw-bold font-size-12">{{ 'login.linked.account.name' | translate }}</div>
              <div class="font-size-14">{{ account.FirstName }} {{ account.Surname }}</div>
            </div>

            <div class="section-info text-start">
              <div class="fw-bold font-size-12">{{ 'login.linked.account.number' | translate }}</div>
              <div class="font-size-14">{{ account.ExternalRefNumber }}</div>
            </div>

            <div class="section-info text-start d-lg-block d-none">
              <div class="fw-bold font-size-12">{{ 'login.linked.account.email' | translate }}</div>
              <div class="font-size-14">{{ account.EmailAddress1 }}</div>
            </div>
          </div>

          <div class="d-flex mt-3 d-block d-lg-none">
            <div class="section-info text-start">
              <div class="fw-bold font-size-12">{{ 'login.linked.account.email' | translate }}</div>
              <div class="font-size-14">{{ account.EmailAddress1 }}</div>
            </div>
          </div>

          <div class="d-flex button-linked-container mt-3">
            <ng-container [ngSwitch]="account.UserAccountStatus">
              <div *ngSwitchCase="0">
                <a class="btn btn-danger fw-bold" [href]="account.EmailAddress2">{{
                  'login.linked.account.status.register' | translate
                }}</a>
              </div>
              <div *ngSwitchCase="1">
                <a class="btn btn-success fw-bold" [href]="account.EmailAddress2">{{
                  'login.linked.account.status.login' | translate
                }}</a>
              </div>
              <div *ngSwitchCase="2">
                <a class="btn btn-muted fw-bold" [href]="account.EmailAddress2">{{
                  'login.linked.account.status.activate' | translate
                }}</a>
              </div>
              <div *ngSwitchDefault>
                <i>n/a</i>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <a [routerLink]="'/login'" class="cancel-btn text-danger font-size-14 fw-bold pe-4">
        {{ 'login.linked.accounts.back' | translate }}
      </a>
    </div>
  </div>
</div>

<ng-template #noAccounts>
  <p class="my-2">
    <i>
      We're sorry but an error has occurred. We could not find any accounts for you to choose from.
      <br />
      Please click below to enter your credentials again.
    </i>
  </p>
  <a [routerLink]="['/login']" class="btn btn-primary">Login</a>
</ng-template>
